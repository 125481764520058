import { NgModule } from '@angular/core';
import {Papa} from './papa';

@NgModule({
    imports: [],
    providers: [
        Papa
    ]
})
export class PapaParseModule { }
